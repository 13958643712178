.tab-button {
  padding: 10px 20px;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: background-color 0.2s, color 0.2s;
  background-color: #e5e7eb;
  color: #374151;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.tab-button:hover {
  background-color: #ccdfff;
  color: #022665;
}

.tab-button.active {
  background-color: #022665;
  color: white;
  font-weight: 700;
}