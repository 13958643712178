.app-container {
  font-family: 'Inter', sans-serif;
}

.main-content {
  padding: 20px;
  max-width: 1000px;
  margin: 70px auto 0;
}

.tab-container {
  margin-bottom: 24px;
}

.tab-buttons {
  display: flex;
  margin-bottom: 16px;
}

.search-input {
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
}

.tree-container {
  margin-top: 16px;
}

.toggle-view-button {
  margin-bottom: 16px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s;
}

.toggle-view-button:hover {
  background-color: #ccdfff;
  color: #022665;
}

.toggle-view-button-category {
  background-color: #022665;
  color: white;
}

.toggle-view-button-integration {
  background-color: #022665;
  color: white;
}

.toggle-view-button-category:hover,
.toggle-view-button-integration:hover {
  background-color: #ccdfff;
  color: #022665;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 180px;
  height: 28px;
  margin-right: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccdfff;
  transition: .4s;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 86px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 28px;
}

input:checked + .slider {
  background-color: #99c0ff;
}

input:checked + .slider:before {
  transform: translateX(88px);
}

.toggle-option {
  color: #022665;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
  flex: 1;
  text-align: center;
  white-space: nowrap;
}

input:checked + .slider .toggle-option.category,
input:not(:checked) + .slider .toggle-option.integration {
  color: white;
}

.toggle-label {
  margin-right: 10px;
  font-weight: 600;
  color: #022665;
  display: flex;
  align-items: center;
  height: 28px;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}